/*  .rtl {
    direction: rtl;
 } */

 .item-card-with-form {
    display: flex;
    flex-direction: column;
}

 
.playlist-item-add-form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 10px;
    max-width: 380px;
    margin-bottom: 20px;
}
.playlist-add-item-form-button {
    margin-top: 20px;
}
.playlist-add-item-form-button {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    gap: 10px;
    font-size: 24px;
}
form.playlist-item-add-form {
    display: flex;
    flex-direction: column;
}

.playlist-input-warper {
    display: flex;
    gap: 10px;
}
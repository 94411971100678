.page-preloader {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  background-color: white;
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-preloader__spinner {
  display: block;
  width: 100px;
  height: 100px;
  border: 4px solid #444;
  border-bottom-color: #888;
  border-radius: 50%;
  animation: spin 0.75s infinite linear;
  z-index: 1000;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

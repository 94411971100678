.editable-text__wrapper {
  /*     display: flex;
    justify-content: center;
    align-items: center; */
}

.editable-text__container {
  /*     background-color: white;
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); */
}

.editable-text__text {
  font-size: 1.25rem;
  cursor: pointer;
}

.editable-text__form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  border-bottom: 2px solid #3b82f6;
  width: 100%;
}
.editable-text__input {
  outline: none;
  font-size: 1.25rem;
  width: 100%;
 
}
 
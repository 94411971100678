.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 0.3s ease;
}

.popup-content {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  width: 95vw;
  height: 80vh;
  max-width: 500px;
  justify-content: space-between;
  overflow-y: auto;
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

.content-fade-in {
  transform: translateY(0);
  opacity: 1;
}

.content-fade-out {
  transform: translateY(-100%);
  opacity: 0;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  transition: color 0.2s ease;
}

.close-button:hover {
  color: #000;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

button:hover {
  background-color: #0056b3;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 95vw !important;
  height: 90vh !important;
  max-width: 500px !important;
  overflow-y: auto;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  transition: color 0.2s ease-in-out;
}

.close-button:hover {
  color: black;
}


svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.popup-overlay-close-button.css-i4bv87-MuiSvgIcon-root {
  background-color: white;
  width: 32px;
  height: 32px;
  padding: 0;
  border-radius: 50%;
  padding: 2px;
  box-sizing: border-box;
  box-shadow: rgb(0 0 0 / 85%) 0px 0px 10px;
  cursor: pointer;
}
 
.popup-overlay-close-button{
  background-color: white ;
  border-radius: 50%;
}
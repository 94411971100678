.price-container {
  grid-area: price;
  font-size: 80px;
}
.price-container {
  position: relative;
  height: 126px;
  text-align: center;
}

.new-price1 {
  color: green;
  animation: line 1s ease-in-out forwards;
}
.old-price1 {
  text-decoration: line-through;
  animation: line-through 1s ease-in-out forwards;
  color: red;
 
}

@keyframes line {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.8);
  }
  75% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1.8);
  }
}
@keyframes line-through {
  11% {
    transform: scale(1.2);
  }
  22% {
    transform: scale(0.8);
  }
  33% {
    transform: scale(1);
  }
  44% {
    transform: scale(0.6);
  }
  55% {
    transform: scale(0.8);
  }
  66% {
    transform: scale(0.4);
  }
  77% {
    transform: scale(0.6);
  }

  100% {
    transform: scale(0.4);
  }

}
.template__image-list{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  
  padding: 10px;
  height: 100%;


}
.no-devices {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
}
.device-list_not-found-devices{
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1.5rem;
    color: #9e9e9e;
    font-weight: 500;
    text-align: center;
    padding: 0 1rem;
    background-color: #f5f5f5;
    border-radius: 0.5rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
    margin: 0.5rem;
    border: 1px solid #e0e0e0;
    transition: all 0.3s;
} 
.children-model {
  max-height: 90vh;

  overflow-x: hidden;
  overflow-y: auto;

  padding-bottom: 20px;
  box-sizing: border-box;
}
span.device__text.device_text-type_מאשר {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 5px;
}

img.device__image {
  margin: 4px 0;
}

.model {
 
  overflow: auto;
  padding-bottom: 20px;
  min-width: 320px;
  width: 98vw;
  height: 90vh;
max-width: 500px;
}

.model {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.children-model.p-4 {
  height: calc(100% - 61px);
}

.model-header {
  display: flex;
  flex-direction: row-reverse;
}

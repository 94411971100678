.App {
  position: relative;
  overflow: hidden;
  height: 100vw;
  width: 100%;
}
div#root {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

.DisplayDeviceComponentPopup {
}

.popup-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  flex-wrap: nowrap;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: fixed !important;
  bottom: 0 !important;
  height: 97vh !important;
  background-color: transparent !important;
  padding: 0 4px !important;
  max-height: unset !important;
 
}

 
.file-upload__link {
}

.buttons__containerx {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 20px;
}

.buttons__containerx {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
  flex-direction: row-reverse;
  align-items: center;
}

.buttons__container_control {
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.hide {
  display: none;
}
.show {
  display: flex;
}

video.file-preview__video {
  width: 80%;
  margin-top: 20px;
}

.filePreview__container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.file-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  direction: rtl;
  margin: 0;
  padding: 0;
  gap: 9px;
}

p {
  margin: 0;
  padding: 0;
}

.file-preview {
}

.file-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  direction: rtl;
  margin: 0;
  padding: 0;
  gap: 9px;
}

p {
  margin: 0;
  padding: 0;
}

.file-preview {
}

p.path {
  /* text-align: center; */
  direction: ltr;
}

.filePreview {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.fileUploadButton {
  font-size: "80px";
  width: "80px";
  height: "80px";
}

.file-preview__info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: row;

  gap: 10px;
}

.file-preview__info {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  direction: ltr;
  text-align: center;
}
span.file-preview__info-item {
}

.file-preview__title-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}

.file-preview__info-title {
  font-weight: 800;
}

span.file-preview__info-title {
  font-family: "Rubik", Arial, sans-serif; /* Arial and sans-serif as fallbacks */
  font-weight: 600; /* Semi-bold */
  font-size: 20px;
}

.file-preview__title-container {
  font-family: "Rubik", Arial, sans-serif; /* Arial and sans-serif as fallbacks */
  /* Semi-bold */
}

h2.file-preview__title {
  font-family: "Rubik", Arial, sans-serif; /* Arial and sans-serif as fallbacks */
  font-weight: 600; /* Semi-bold */
  font-size: 24px;
  margin: 0;
  padding: 10px 0;
}

.file-preview {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px,
    rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
  padding: 5px 10px;
  width: 96%;
  box-sizing: border-box;
  border-radius: 5px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-bottom: 20px;
}

img {
}

.file-preview__title-container {
}

.file-preview__title-container {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  width: 100%;
  border-radius: 6px;
}

.file-preview__info-container {
  justify-content: space-between;
  width: 100%;
  padding-bottom: 10px;
  text-align: center;
}

.file-upload__loading {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
  gap: 0 20px;
  width: 80%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 6px;
  padding: 10px 0;
}

.file-upload__loading-bar {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
}
.file-upload-percentage {


  text-align: center;
  grid-area: 1 / 1 / 2 / 2;
  align-self: center;
}

#file-upload__CircularProgress {
  grid-area: 1 / 1 / 2 / 2;
 width: 60px !important; 
 height: 60px !important;
}
.file-upload__successful {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row-reverse;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  color: #270;
  background-color: #DFF2BF;
  padding: 20px;
  border-radius: 3px;
  font-size: 20px;
}
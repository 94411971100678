.playlist-browse-page {
  display: flex;
  flex-direction: column;
   align-items: center;
  margin: unset;
  padding: 25px 0;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  flex-direction:row-reverse;
}

.page-title {
 
  font-size: 24px;
    font-weight: bold;
    color: #111827;
    direction: rtl;
 
}

.btn {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border: 1px solid transparent;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 0.375rem;
  cursor: pointer;
}

.btn-primary {
  background-color: #4F46E5;
  color: white;
}

.btn-primary:hover {
  background-color: #4338CA;
}

.btn-secondary {
  border-color: #D1D5DB;
  background-color: white;
  color: #374151;
}

.btn-secondary:hover {
  background-color: #F3F4F6;
}

.btn-icon {
  height: 1.25rem;
  width: 1.25rem;
margin-left: 4px;
}

.loading-container {
  text-align: center;
}

.loading-icon {
  margin: 0 auto;
  height: 3rem;
  width: 3rem;
  color: #9CA3AF;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.loading-text {
  margin-top: 0.5rem;
  font-size: 1.125rem;
  font-weight: 500;
  color: #111827;
}

.playlist-list {
  direction: rtl;
  background-color: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 0.375rem;
  width: 100%;
  overflow-y: auto;
  
}

.playlist-items {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.playlist-item {
  border-bottom: 1px solid #E5E7EB;
}

.playlist-item:last-child {
  border-bottom: none;
}

.playlist-content {
  padding: 1rem;
}

.playlist-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.playlist-name {
  font-size: 0.875rem;
  font-weight: 500;
  color: #4F46E5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.status-badge {
  padding: 0.125rem 0.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  border-radius: 9999px;
}

.status-badge.active {
  background-color: #D1FAE5;
  color: #065F46;
}

.status-badge.inactive {
  background-color: #FEE2E2;
  color: #991B1B;
}

.playlist-details {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
}

.playlist-info {
  display: flex;
}

.playlist-duration, .playlist-items-count, .playlist-date {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: #6B7280;
}

.playlist-items-count {
  margin-right: 1.5rem;
  cursor: pointer;
}

.playlist-items-count:hover {
  color: #4F46E5;
}

.info-icon {
  margin-left: 0.375rem;
  height: 1.25rem;
  width: 1.25rem;
  color: #9CA3AF;
}

.playlist-actions {
  margin-top: 1rem;
  display: flex;
  gap: 0.75rem;
}

.playlist-content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
}

p.playlist-items-count {
    margin: unset;
}

.playlist-info {
    display: flex;
    flex-direction: column;
}

.playlist-content {
    gap: 5px;
}

.playlist-details {
    flex-direction: column;
    gap: 5px;
}

.playlist-header {
}

.playlist-info {
    gap: 5px;
}

.playlist-actions {
  flex-wrap: wrap;
}

.items-actions {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 9px;
}

.btn.btn-add {
  display: flex;
  gap: 1px;
  flex-direction: row-reverse;
}


ul.item-list {
  direction: rtl;
}

.item-info {
  gap: 5px;
}

.btn-add-playlist {
padding: 10px;
}

.page-header{
  display: flex;
    justify-content: space-between;
    align-items: center;
 
    flex-direction: row-reverse;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 10px;
    border-radius: 10px;

    width: 100%;
}

.btn-icon-add-playlist{
  width: 40px;
  height: 30px;
  margin: 0 ;
}

.page-header {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: space-around;
  justify-content: center;
}

.page-header-warper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

.page-header {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: space-around;
  justify-content: center;
}

.page-header-warper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

.page-header {}

.page-actions {}

.page-device-info {
  direction: rtl;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 0;
}
.playlist-count {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.playlist-header {
}

.playlist-details {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    gap: 60px;
}
.playlist-details {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  gap: 60px;
}

.playlist-duration {}

.playlist-details {
  gap: 1;
}

.playlist-right {
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.playlist-left {
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: flex-start;
}

.playlist-details {
  max-width: 310px;
  align-self: anchor-center;
  flex-direction: unset;
}



.page-device-info{
  box-shadow: unset;
}

.playlist-details {
  gap: 0;
  gap: 0 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

.playlist-content {
  gap: 5px;
  padding: 10px 5px;
}

.playlist-actions {
  margin: 0;
}

.playlist-actions-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 0 10px;
  margin-left: 6px;
}

.playlist-header {
  margin-bottom: 10px;
}

.playlist-details {
}

.playlist-actions {
  margin-top: 10px;
}
.playlist-browse-page {
  max-width: 500px;
  width: 100%;
}

.playlist-actions {align-self: anchor-center;/* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */margin-top: 20px;}

button.btn.btn-secondary {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

button.btn.playlist-btn-add {
  background: transparent;
  padding: 0;
  width: 32px;
  height: 32px;
}

.PlaylistBrowse-plus-circle {
}

svg.lucide.lucide-circle-plus.PlaylistBrowse-plus-circle {
  color: #00917C;
  width: 28px;
  height: 28px;
}
.playlist-browse-page {
  max-width: 500px;
  width: 100%;
}

.playlist-actions {
  align-self: anchor-center;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  margin-top: 20px;}
  button.btn.btn-secondary {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
};
.popup-content {
  height: 90%;
  bottom: 0;
  position: fixed;
  width: 102vw;
  overflow-x: hidden;
  background-color: transparent;
}

ul.playlist-items {
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.close-button.css-1idedao-MuiSvgIcon-root {
  display: inline-block;
  position: unset;
  color: black;
  width: 28px;
  height: 28px;
  box-shadow: rgb(0 0 0) 0px 1px 4px;
  border-radius: 5px;
  top: 0px;
  background-color: #ffffffe8;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.close-button.css-1idedao-MuiSvgIcon-root {}

.popup-content {}

.playlist-browse-page {
  margin-top: 12px;
  padding: 0;
  background-color: white;
  border-radius: 9px;
}
.playlist-browse-page {
  overflow: hidden;
  height: 100%;
}
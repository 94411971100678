/* action-cards.css */
.action-card {
  --hover-text-color: #ffffff;
  transition: color 0.3s ease-in-out;
}
.full-page-container {
  display: flex;
  height: 100vh;
  width: 100%;
  padding: 1rem;
  background-color: #f3f4f6;
}

.dark .full-page-container {
  background-color: #111827;
}

.cards-container {
  display: flex;
  width: 100%;
  max-width: 72rem;
  margin: 0 auto;
}

.card-wrapper {
  flex: 1;
  padding: 0 0.5rem;
}

.action-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 0.75rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
}

.dark .action-card {
  background-color: #1f2937;
  border-color: #374151;
}

.action-card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.card-gradient {
  position: absolute;
  inset: 0;
  background: linear-gradient(to bottom right, #60a5fa, #a855f7);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.action-card:hover .card-gradient {
  opacity: 0.1;
}

.card-icon {
  width: 4rem;
  height: 4rem;
  margin-bottom: 1rem;
  color: #3b82f6;
  transition: all 0.3s ease-in-out;
}

.dark .card-icon {
  color: #60a5fa;
}

.action-card:hover .card-icon {
  transform: scale(1.1) rotate(3deg);
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 0.5rem;
  transition: color 0.3s ease-in-out;
}

.dark .card-title {
  color: white;
}

.action-card:hover .card-title {
  color: #2563eb;
}

.dark .action-card:hover .card-title {
  color: #3b82f6;
}

.card-description {
  font-size: 0.875rem;
  color: #4b5563;
  text-align: center;
  max-width: 20rem;
  padding: 0 1rem;
  margin-bottom: 1rem;
}

.dark .card-description {
  color: #9ca3af;
}

.card-action {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  transform: translateY(1rem);
}

.action-card:hover .card-action {
  opacity: 1;
  transform: translateY(0);
}

.card-action-text {
  font-size: 0.875rem;
  color: #3b82f6;
  margin-right: 0.5rem;
}

.dark .card-action-text {
  color: #60a5fa;
}

.card-action-icon {
  width: 1rem;
  height: 1rem;
  color: #3b82f6;
}

.dark .card-action-icon {
  color: #60a5fa;
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slide-in-left {
  animation: slideInLeft 0.5s ease-out;
}

.animate-slide-in-right {
  animation: slideInRight 0.5s ease-out;
}

.card-gradient {
}

.card-wrapper {
  height: 300px;
  max-width: 300px;
  min-width: 200px;
  padding: unset;
}

.full-page-container {
  height: unset;
  border-radius: 20px;
  background-color: unset;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
}

.cards-container {
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  gap: 30px 10px;
  margin: unset;
}


.card-action {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 3px;
}

span.card-action-text {
  font-size: 30px;
}

.card-action-icon {
  width: 30px;
  height: auto;
}
 

.action-card:hover .card-description,
.action-card:hover .card-title,
.action-card:hover .card-icon,
.action-card:hover .card-action-text,
.action-card:hover .card-action-icon {
  color: var(--hover-text-color);
}

/* Optional: Add a transition to the initial state of these elements */
.card-description,
.card-title,
.card-icon,
.card-action-text,
.card-action-icon {
  transition: all 0.3s ease-in-out;
}

.card-wrapper {
  height: 225px;
  min-width: 220px;
}

.TvForm_warper {
}
.TvForm {
  display: flex;
  gap: 20px;
  flex-direction: column;
  direction: rtl;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  font-size: 30px;
}
#text {
  font-size: 22px;
  color: rebeccapurple;
  height: 100px;
}

.select_img {
  background-image: url("https://via.placeholder.com/150");
  background-size: 50px 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f1f1f1;
  cursor: pointer;
}
.hidden {
  display: none;
}
.custom-select {
  /* Custom styling */
}
.options-container {
  /* Custom styling */
}
.option {
  /* Custom styling */
}

.TvForm_warper {
  display: flex;
  width: 100%;
  justify-content: center;
}

form.TvForm {
  display: flex;
  gap: 20px;
  direction: rtl;
  font-size: 30px;
  width: 100%;
  max-width: 320px;
}

form.TvForm {
  margin-top: 10px;
}

.form__field {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  /* padding: 5px 20px; */
  padding-left: 10px;
  box-sizing: border-box;
  font-size: 21px;
  padding-right: 10px;
}

.dropdown-header {
  width: 300px;
}

.dropdown-header {
}

.form__input {
  outline: none;
  border: none;
  background-color: #dcdcdc47;
  height: 30px;
  border-bottom-style: inset;
  border-bottom-color: #2c3e50;
  font-size: 21px;
  min-width: 170px;
  width: 100%;
  border-bottom-color: #3498db;
}

.form__label {
  width: 100%;
}

.form__field {
  flex-direction: column;
}

form.TvForm {
  gap: 10px;
}

label.form__label {
  background-color: #c23616;
  min-width: 150px;
  color: white;
  padding: 5px;
  font-size: 18px;
  border-radius: 6px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  box-shadow: rgb(0 0 0 / 59%) 0px 3px 8px;
  width: max-content;
  text-align: center;
}

label.form__label {
  color: black;
  background-color: unset;
  border-bottom-color: #c23616;
  border-bottom-style: solid;
}

/* hdhhc */

textarea {
  width: 215px;
}

.dropdown-header {
  width: 229px;
  font-size: 28px;
}

span.dropdown__item-label {
  font-size: inherit;
}

#test1-label {
  font-size: 16px;
  direction: rtl !important;
  text-align: right;
  width: 100%;
}

::-webkit-input-placeholder {
  font-size: 16px;
  direction: rtl !important;
  text-align: right;
  width: 100%;
}
.dropdown-header {
  width: 220px;
  box-shadow: unset;
}

.dropdown-container {
  box-shadow: rgb(0 0 0 / 12%) 0px 5px 15px;
}

.dropdown-container {
  cursor: pointer;
}

ul.dropdown-list {
  cursor: pointer;
}

li.dropdown_li {
  cursor: pointer;
}

img.dropdown-img {
  cursor: pointer;
}

label.dropdown__label {
  cursor: pointer;
}
 
.TvForm_warper {
  padding-bottom: 30px;
  max-width: 300px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-bottom: 40px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 21px;
  padding-top: 10px;
}

.TvForm__info {
  font-size: 30px;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 94%;
  text-align: center;
  direction: ltr;
  padding: 5px 0;
  margin-bottom: 3px;
  align-self: center;
}

.TvForm_warper {
  flex-direction: column;
}

.form__campaign {
}
.tv-id {
}
.tv-form__title {
  margin: 0;
  padding: 0;
  font-size: inherit;
}

.tv-form__campaign {
  margin: 0;
  padding: 0;
  font-size: inherit;
}

.tv-form__tv-id {
  margin: 0;
  padding: 0;
  font-size: inherit;
}
h2.tv-form__campaign {
  font-size: 14px;
  color: #6262623b;
}

h2.tv-form__tv-id {
  font-size: 10px;
  direction: rtl;
  color: #6262623b;
}

.TvForm__info {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.tv-form__title {
  padding: 2px 32px;
  margin: 0;
  font-size: inherit;
  width: MAX-CONTENT;
  text-align: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
h2.tv-form__campaign {
  font-size: 12px;
}

h1.tv-form__title {
  padding-top: 10px;
  padding-bottom: 10px;
}

.TvForm__info {
  padding-bottom: 10px;
}

h2.tv-form__campaign {
  margin-top: 10px;
}
form.TvForm__fetachData {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 5px;
  max-width: 97%;
}

.rotate {
  animation: rotate 0.5s linear infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.opacityLoading {
  animation: opacityLoading 1s linear infinite;
}
@keyframes opacityLoading {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

.TvForm__images {
  max-width: 99%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  row-gap: 10px;
}
.TvForm__image {
  width: 80px;
  height: auto;
}

.TvForm__images {
  max-width: 99%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  row-gap: 10px;
}

.TvForm__image {
  width: 80px;
  height: auto;
}

.TvForm__main-image {
  width: 112px;
  background-color: #f44336;
  padding: 10px 5px;
  border-radius: 6px;
  box-sizing: border-box;
}
.button-delete {
  background-color: transparent;

  border: none;
  border-radius: 6px;
  padding: 0;
  cursor: pointer;
  font-size: 16px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.imgCompoent {
  display: grid;
  grid-area: 1 / 1 / 2 / 2;
}

img.TvForm__image {
  grid-area: 1 / 1 / 2 / 2;
  width: 100%;
  height: 100%;
  max-height: 100px;
  max-width: 125px;
}

button.button-delete {
  background-color: white;
  border: none;
  border-radius: 6px;
  padding: 0;
  cursor: pointer;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: absolute;
}

.TvForm__images {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.imgCompoent {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  position: relative;
}

.TvForm__image {
  width: 144px;
  height: 144px;
}

button.button-delete {
  /*   box-shadow: rgb(0 0 0) 0px 7px 29px 0px, rgb(174 174 176) 0px 7px 29px 0px, inset rgb(0 0 0 / 26%) 0px 0px 29px 20px;
  */
  background-image: url(../../assets/elements/trash-solid.webp); /* The image used */
  background-color: #cccccc; /* Used if the image is unavailable */
  height: 500px; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}
button.button-delete {
  background-size: 30px 34px;
  width: 36px;
  height: 38px;
  background-color: #0000001f;
  backdrop-filter: blur(5px);
  box-shadow: rgb(0 0 0) 0px 7px 29px 0px, rgb(174 174 176) 0px 7px 29px 0px,
    inset rgb(0 0 0 / 6%) 0px 0px 29px 20px;
}

.animated-background {
  width: 100%; /* Set the width as needed */
  height: 400px; /* Set the height as needed */
  background-size: cover;
  background-position: center;
  animation: backgroundChange 9s infinite;
  width: 100%;
  height: 400px;
  background-size: contain;
  background-position: center;
  animation: backgroundChange 9s infinite;
  background-repeat: no-repeat;
  margin-top: 20px;
}

div > label#title-label,
div > label#oldPrice-label,
div > label#newPrice-label,
div > label#barcode-label {
  text-align: center;
  direction: rtl;
  width: 100%;

}



.TvForm__barcode {
  display: flex;
  gap: 5px;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.TvForm__barcode {
  display: flex;
  gap: 5px;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.TvForm__image-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

#barcode-helper-text {
  margin: 0;
  text-align: right;
  margin-right: 10px;
  margin-top: 3px;

  transition: all 2s;
}

.TvForm__image-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  /* width: 100%; */
  flex-wrap: wrap;
}

.tv-button {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: auto;
  border-radius: 50%;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
}

.TvForm__image-items {
}

.TvForm__images-items {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.TvForm__image-item {
  width: 100%;
  max-width: 300px;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  /* width: 100%; */
  flex-wrap: wrap;
}

button.TvForm__delete-image-button {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
  background-color: #ffffff42;
  box-shadow: inset rgb(0 0 0 / 2%) 0px 3px 9px 20px;
}

img.TvForm__image {
  width: 100%;
  height: 100%;
  max-width: 30xpx;
  max-height: 300px;
}

.TvForm__images-items {
  gap: 10px;
}

.TvForm__image-item {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.TvForm__images-items {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  flex-direction: row;
  padding: 5px 10px;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 10px;
}

.TvForm__image-item {
  display: block;
  max-width: 120px;
  padding: 5px;
}

.template__image-list.animated-background {
  width: 100px;
  height: 100px;

  animation: backgroundChange 9s infinite;
}

 
.template__image-list.animated-background {
  grid-area: image;
  width: 100%;
  height: 100%;
  margin: 0;
}

.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}

 

.btnTemp {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  /* background-color: white; */
  border: none;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
  position: absolute;
  /* top: 0; */
  left: 0;
}

.TvForm__barcode {
  width: 95%;
  box-sizing: border-box;
  position: relative;
}
.text {
  align-self: center;
 
}

.template__image-list.animated-background {
  background-repeat: no-repeat;
 
}
h1.title {
  justify-self: center;
}
.oldPrice {
  font-size: 100px;
  letter-spacing: 10px;
  opacity: 0;
}
.newPrice {
  font-size: 100px;
  letter-spacing: 10px;
  opacity: 0;
}


.dropdown-container {
  width: 95%;
  background-color: #f0f0f0;
  border-bottom: 3px solid #03A9F4;
}

.dropdown-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
}

.TvForm__info {direction: rtl;}
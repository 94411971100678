.modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(75, 85, 99, 0.5);
  overflow-y: auto;
  height: 100%;
  width: 100%;
  z-index: 50;
}

.modal-container {
  position: relative;
  top: 5rem;
  margin: 0 auto;
  padding: 1.25rem;
  border: 1px solid #e5e7eb;
  width: 100%;
  max-width: 48rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
  background-color: white;
}

.modal-content {
  margin-top: 0.75rem;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.modal-title {
  font-size: 1.125rem;
  font-weight: 500;
  color: #111827;
}

.modal-actions {
  display: flex;
  gap: 0.5rem;
}

.btn {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  border: 1px solid transparent;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 0.375rem;
  cursor: pointer;
}

.btn-save {
  background-color: #10b981;
  color: white;
}

.btn-save:hover {
  background-color: #059669;
}

.btn-add {
  background-color: #4f46e5;
  color: white;
}

.btn-add:hover {
  background-color: #4338ca;
}

.btn-icon {
  cursor: pointer;
  height: auto;
  width: 24px;
}

.item-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-top: 1px solid #e5e7eb;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 1px solid #e5e7eb;
}

.item-info {
  display: flex;
  align-items: center;
}

.icon {
  height: 1.25rem;
  width: 1.25rem;
}

.icon-video {
  color: #3b82f6;
}

.icon-image {
  color: #10b981;
}

.icon-file {
  color: #6b7280;
}

.item-details {
  margin-right: 1rem;
}

.item-type {
  font-size: 0.875rem;
  font-weight: 500;
  color: #111827;
}

.item-id {
  font-size: 0.875rem;
  color: #6b7280;
}

.item-actions {
  display: flex;
  align-items: center;
}

.item-duration,
.item-order {
  font-size: 0.875rem;
  color: #6b7280;
  margin-left: 1rem;
}

.btn-edit {
  margin-left: 0.5rem;
}

.btn-edit:hover {
}

.modal-footer {
  margin-top: 1.25rem;
}

.btn-close {
  width: 100%;
  justify-content: center;
  background-color: #4f46e5;
  color: white;
}

.btn-close:hover {
  background-color: #4338ca;
}

.btn-delete {
  padding: 0;
}

.item-details {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: 0;
}

.item-info {
  width: 100%;
}

.item-side2 {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
span.item-info--text {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.item-info--icon {
  margin-left: 2px;
  width: 16px;
  height: auto;
}

span.item-info--text {
  justify-content: flex-start;
}

.item-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 20px;
  align-content: center;
  justify-content: center;
  align-items: center;
}

svg.lucide.lucide-file-text.icon.icon-file {
  width: 30px;
  height: 40px;
}

h3.item-name {
  min-width: 100px;
}

.item-details {
  justify-content: space-evenly;
}

.modal-container {
  padding: 10px;
}

.item-side2 {
  justify-content: center;
  align-items: flex-start;
}

.displayStages {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.icon-back {
  color: #fff;
  width: 24px;
  height: 24px;
}

.icon-back-button {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: max-content;
  padding: 6px 14px;
  align-self: end;
  font-size: 20px;
  margin-bottom: 20px;
}

.reactItems {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.editable-text__form {
  flex-direction: row-reverse !important;
}

ul.item-list {
  direction: rtl;
}

.item-info {
  gap: 5px;
}

ul.playlist-items {
  display: flex;
  flex-direction: column;
}

li.playlist-item {
}

.items-actions {
  justify-content: space-around;
}

button.btn.btn-save {
  display: flex;
  flex-direction: row-reverse;
}

.item-card__img {
  height: 100px !important;
}
.empty-state{
  width: 100%;
}
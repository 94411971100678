 .timeline__container {
  gap: 6px;
  display: flex;
  margin-bottom: 12px;
  position: relative;
}

.timeline__icon-container {
  flex: none;
  position: relative;
}

.timeline__icon {
  background-color: #3b82f6;
  color: white;
  border-radius: 10px;
  height: 60px;
  padding: 8px;
  z-index: 10;
  position: relative;
}

.timeline__expand-box {
  background-color: #f3f4f6;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  width: 100%;
}

.timeline__header {
  padding: 1rem;
  display: flex;
  gap: 6px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.timeline__time {
  padding: 0.25rem;
  background-color: #e5e7eb;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.timeline__chevron {
  height: 1rem;
  width: 1rem;
}

.timeline__content {
  transition: all 0.3s ease-in-out;
}

.timeline__buttons {
  padding: 1rem;
  padding-top: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 0.5rem;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.timeline__buttons.show {
  opacity: 1;
}

.timeline__button {
  padding: 0.5rem;
  color: white;
  border-radius: 9999px;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
}

.timeline__button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.5);
}

.timeline__button--delete { background-color: #ef4444; }
.timeline__button--delete:hover { background-color: #dc2626; }

.timeline__button--link { background-color: #3b82f6; }
.timeline__button--link:hover { background-color: #2563eb; }

.timeline__button--edit { background-color: #10b981; }
.timeline__button--edit:hover { background-color: #059669; }

.timeline__button--share { background-color: #6366f1; }
.timeline__button--share:hover { background-color: #4f46e5; }

.delete-confirm {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.delete-confirm__content {
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.delete-confirm__title {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.delete-confirm__message {
  margin-bottom: 1rem;
}

.delete-confirm__buttons {
  display: flex;
  justify-content: space-between;
}

.delete-confirm__button {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  transition: background-color 0.2s ease-in-out;
}

.delete-confirm__button--cancel {
  background-color: #d1d5db;
  color: #1f2937;
}

.delete-confirm__button--cancel:hover {
  background-color: #9ca3af;
}

.delete-confirm__button--confirm {
  background-color: #ef4444;
  color: white;
}

.delete-confirm__button--confirm:hover {
  background-color: #dc2626;
}
:root {
  --primary-color: #3f51b5;
  --secondary-color: #f50057;
  --background-color: #f5f5f5;
  --text-color: #333;
  --card-background: #ffffff;
  --card-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  --transition-speed: 0.3s;
}

.device-item {
  background-color: var(--card-background);
  border-radius: 8px;
  box-shadow: var(--card-shadow);
  transition: transform var(--transition-speed), box-shadow var(--transition-speed);
  overflow: hidden;
  margin-bottom: 20px;
}

.device-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.device-info {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.device__image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.device-actions {
  display: flex;
  justify-content: flex-end;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.03);
}

.device-actions button {
  margin-left: 8px;
}

.device-info__text-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

.device-name {
  font-weight: bold;
  color: var(--primary-color);
}

.device_text-type_id {
  font-size: 1.2em;
  color: var(--secondary-color);
}

.device_text-type_title {
  font-size: 1.1em;
}

.device__text {
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 5px;
  color: #000;
  font-size: 16px;
  text-transform: capitalize;
}

.device_text-type_approved-by,
.device_text-type_place,
.device_text-type_updatedAt,
.device_text-type_userAgent {
  font-size: 0.9em;
  color: #666;
}

/* Icon styles */
.MuiSvgIcon-root {
  font-size: 1.2rem;
}

.MuiIconButton-root {
  color: var(--primary-color);
  transition: background-color var(--transition-speed);
}

.MuiIconButton-root:hover {
  background-color: rgba(63, 81, 181, 0.04);
}

.MuiIconButton-root[aria-label="delete"]:hover {
  color: var(--secondary-color);
}
/* Responsive design */
.device-actions {
  width: 96%;
  border-radius: 5px;
  padding: 6px;
  box-sizing: border-box;
  align-self: center;
  display: flex;
  justify-content: space-between;
  margin: 4px 0;
}

.device-actions {}

.device-info {padding: 0;}

.device-info__text-wrapper {
  padding: 6px;
}

img.device__image {
  padding: 3px;
}

.flex.flex-col.items-start.p-4.bg-gray-100.rounded-t-lg {}

.flex.flex-col.items-start.p-4.bg-gray-100.rounded-t-lg.device-item-header {}

.device-item-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 7px;
    align-content: center;
    align-items: center;
    width: 100%;
}

li.device-item {
  padding-bottom: 10px;
}

ul.device-list-ul {
    display: flex;
    flex-direction: row-reverse;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    gap: 20px;
}

.device-list {
    width: 100%;
}

li.device-item {
    width: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
}

img.device__image {
    width: 200px;
    height: auto;
    align-self: initial;
}

.device-info {
    width: 100%;
}

.device-info__text-wrapper {
    align-self: flex-end;
}

img.device__image {
  margin: 4px 0;
}
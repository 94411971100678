
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Rubik";
  src: url("./assets/fonts/Rubik/Rubik-VariableFont_wght.ttf") format("truetype"),
       url("./assets/fonts/Rubik/static/Rubik-Black.ttf") format("truetype"),
       url("./assets/fonts/Rubik/static/Rubik-BlackItalic.ttf") format("truetype"),
       url("./assets/fonts/Rubik/static/Rubik-Bold.ttf") format("truetype"),
       url("./assets/fonts/Rubik/static/Rubik-ExtraBold.ttf") format("truetype"),
       url("./assets/fonts/Rubik/static/Rubik-ExtraBoldItalic.ttf") format("truetype"),
       url("./assets/fonts/Rubik/static/Rubik-Italic.ttf") format("truetype"),
       url("./assets/fonts/Rubik/static/Rubik-Light.ttf") format("truetype"),
       url("./assets/fonts/Rubik/static/Rubik-LightItalic.ttf") format("truetype"),
       url("./assets/fonts/Rubik/static/Rubik-Medium.ttf") format("truetype"),
       url("./assets/fonts/Rubik/static/Rubik-Regular.ttf") format("truetype"),
       url("./assets/fonts/Rubik/static/Rubik-SemiBold.ttf") format("truetype"),
       url("./assets/fonts/Rubik/static/Rubik-SemiBoldItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: "Rubik";
  src: url("./assets/fonts/Rubik/static/Rubik-Bold.ttf") format("truetype");
  font-weight: 700;  
}

@font-face {
  font-family: "Rubik";
  src: url("./assets/fonts/Rubik/static/Rubik-BoldItalic.ttf") format("truetype");
  font-weight: 700; 
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: url("./assets/fonts/Rubik/static/Rubik-ExtraBold.ttf") format("truetype");
  font-weight: 800;  
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("./assets/fonts/Rubik/static/Rubik-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;  
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: url("./assets/fonts/Rubik/static/Rubik-Italic.ttf") format("truetype");
  font-weight: 400;  
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: url("./assets/fonts/Rubik/static/Rubik-Light.ttf") format("truetype");
  font-weight: 300;  
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("./assets/fonts/Rubik/static/Rubik-LightItalic.ttf") format("truetype");
  font-weight: 300; 
  font-style: italic;
}

@font-face {
  font-family: "Rubik";
  src: url("./assets/fonts/Rubik/static/Rubik-Medium.ttf") format("truetype");
  font-weight: 500;  
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("./assets/fonts/Rubik/static/Rubik-Regular.ttf") format("truetype");
  font-weight: 400;  
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("./assets/fonts/Rubik/static/Rubik-SemiBold.ttf") format("truetype");
  font-weight: 600; 
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("./assets/fonts/Rubik/static/Rubik-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;  
  font-style: italic;
}
 * {
  -webkit-tap-highlight-color: transparent !important;
 }

 .scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
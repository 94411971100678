.media-carousel-indicators {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 5px;
}
.media-carousel-text{
    direction: rtl;
    text-align: right;
    font-family: Arial, sans-serif;
    line-height: 2.2;
    white-space: pre-line;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.media-filter-container {
    padding: 1.5rem;
    max-width: 64rem;
    margin: 0 auto;
    direction: rtl;
  }
  
  .filter-controls {
    margin-bottom: 1.5rem;
  }
  
  .filter-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .filter-button {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    background-color: #e5e7eb;
    color: #374151;
    transition: all 0.2s;
  }
  
  .filter-button.active {
    background-color: #3b82f6;
    color: white;
  }
  
  .filter-button span {
    margin-right: 0.5rem;
  }
  
  .search-sort {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .search-container {
    position: relative;
    flex-grow: 1;
    margin-left: 1rem;
  }
  
  .search-input {
    width: 100%;
    padding: 0.5rem 2.5rem 0.5rem 1rem;
    border: 1px solid #d1d5db;
    border-radius: 9999px;
    outline: none;
  }
  
  .search-input:focus {
    border-color: #3b82f6;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
  }
  
  .search-icon {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: #9ca3af;
  }
  
  .clear-search {
    position: absolute;
    right: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: #9ca3af;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .sort-select {
    padding: 0.5rem 1rem;
    border: 1px solid #d1d5db;
    border-radius: 9999px;
    outline: none;
  }
  
  .media-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;
  }
  
  .media-item {
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  
  .media-preview {
    height: 12rem;
    margin-bottom: 1rem;
    overflow: hidden;
    border-radius: 0.375rem;
  }
  
  .template-preview {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3f4f6;
  }
  
  .template-icon {
    color: #9ca3af;
  }
  
  .image-container {
    height: 100%;
  }
  
  .media-image, .media-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .image-not-found-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3f4f6;
  }
  
  .image-not-found {
    width: 100%;
    height: 100%;
    color: #d1d5db;
  }
  
  .media-title {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .media-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
  
  .date-info {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  .date-info svg {
    margin-left: 0.25rem;
  }
  
  .file-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .file-extension, .file-size {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  .file-extension svg, .file-size svg {
    margin-left: 0.25rem;
  }
  
  .tag-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  
  .tag {
    background-color: #dbeafe;
    color: #1e40af;
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    border-radius: 9999px;
  }
  
  @media (max-width: 640px) {
    .search-sort {
      flex-direction: column;
      align-items: stretch;
    }
  
    .search-container {
      margin-left: 0;
 
    }
  }



  .search-sort {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    flex-direction: row;
}

img.media-image {
    object-fit: fill;
}

.filter-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.search-container {
    width: 50%;
    margin-bottom: unset;
}
.media-preview-select-item {/* margin-bottom: 10px; */width: 100px;display: flex;align-items: center;justify-content: center;align-content: center;}

.media-preview-button-continer {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: aquamarine;
    width: 100%;
    margin: auto;
    flex-direction: column;
    border-radius: 20px;
    padding: 4px;
}

.media-preview-button-container {
    display: flex;
    background-color: transparent;
    padding: 4px;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    border-radius: 10px;
    margin-bottom: 10px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06), 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    width: max-content;
    margin: 0 auto;
    margin-bottom: 10px;
}
.template {
  width: 100%;
}

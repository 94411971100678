.collapse-Item {
}
.collapse-Item__content {
}

.collapse-Item__description-warper {
}
.collapse-Item__descriptions {
}

.collapse-Item__content-image {
}
.collapse-Item__content-video {
}

.collapse-Item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.collapse-Item__description-warper {
  flex: 1;
  min-width: 196px;
}

.collapse-Item__content {
  flex: 1;
}

.collapse-Item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}

.collapse-Item__description-warper {
  flex: 1;
  min-width: 196px;
}

.collapse-Item__content {
  flex: 1;
}

.button__ant {
  font-size: 30px;
  width: 200px;
  margin-top: 30px;
  height: 50px;
 
}

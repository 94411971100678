.collapse {
  display: flex;
  flex-direction: row-reverse;
  align-content: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-bottom: 10px;
  border-radius: 8px;
}
.collapse__ant{
    width: 100%;
}
.collapse__remove {
  align-self: flex-start;
  margin-top: 14px;
  font-size: 22px;
}
.test-page {
  background-color: burlywood;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
 
}

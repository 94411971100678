 

.timeline__container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.timeline__container-right {
    display: flex;
    gap: 4px;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.timeline__expand-box {width: 100%;}

button.timeline__expand-remove {
    position: unset;
    border-radius: 4px;
    margin: 0;
    padding: 10px;
}

.timeline__container-right.flex-grow.pl-4.relative {
    gap: 10px;
}

.timeline__warper {
    width: 100%;
    max-width: unset;
    padding: 0;
    direction: rtl;
}
.timeline-item__icon{
width: 40px;
height: 40px;
}
.timeline__icon{
 
    color: rgb(255 255 255 / var(--tw-text-opacity));
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    padding: 1px;
    font-size: 40px;
}
.playlist-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}

.form-group {
  margin-bottom: 1.5rem;
}

.label {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
}

.icon {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.5rem;
  color: #4a90e2;
}

.input,
.textarea,
.select {
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
  border: 2px solid #e1e5ea;
  border-radius: 8px;
  background-color: #fff;
  transition: all 0.3s ease;
}

.input:focus,
.textarea:focus,
.select:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.1);
}

.textarea {
  min-height: 100px;
  resize: vertical;
}

.error {
  color: #e74c3c;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.tag-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.tag {
  display: inline-flex;
  align-items: center;
  background-color: #4a90e2;
  color: #fff;
  padding: 0.25rem 0.5rem;
  border-radius: 16px;
  font-size: 0.875rem;
}

.tag-remove {
  background: none;
  border: none;
  color: #fff;
  font-size: 1rem;
  margin-left: 0.25rem;
  cursor: pointer;
}

.tag-input {
  display: flex;
  gap: 0.5rem;
}

.button {
  padding: 0.8rem 1.5rem;
  background-color: #4a90e2;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #3a7bc8;
}

.checkbox-label {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #333;
  cursor: pointer;
}

.checkbox {
  margin-right: 0.5rem;
  width: 1.2rem;
  height: 1.2rem;
}

.submit-button {
  width: 100%;
  padding: 1rem;
  background-color: #4a90e2;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover {
  background-color: #3a7bc8;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(74, 144, 226, 0.2);
}

form.playlist-form {
  background: unset;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.form-group {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  direction: rtl;
  flex-direction: row;
}

.playlist-form {
  align-items: flex-end;
}

.form-group {
  gap: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.label {
  width: 100px;
  gap: 10px;
}

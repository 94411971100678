.AddSource {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

input#myFile {
  width: 169px;
  margin-top: 10px;
}
.DevicePage__buttons {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

.DevicePage__button {
  outline: none;
  border: unset;
  background-color: unset;
  background-color: #0097e6;
  font-size: 27px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.DevicePage__buttons {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
  margin-bottom: 20px;
}

.DevicePage__button {
  outline: none;
  border: unset;
  background-color: unset;
  background-color: #0097e6;
  font-size: 27px;
  color: white;
  border-radius: 5px;
}
.DevicePage__button_type_video {
  background-image: url("../../assets/elements/videoIcon.webp");
  background-size: 40px 40px;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
  padding-right: 50px;
  height: 42px;
}
.DevicePage__button_type_template {
  background-image: url("../../assets/elements/templateIcon.webp");
  background-size: 40px 40px;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
  padding-right: 50px;
  height: 42px;
}

.DevicePage__button {
  outline: none;
  border: unset;
  background-color: unset;
  background-color: #1876d2;
  font-size: 27px;
  color: white;
  border-radius: 5px;
}
#addFile {
  line-height: 0;
  padding: 0px;
  margin: 0;
  box-sizing: border-box;
  padding-left: 10px;
  border-radius: 12px;
  width: 230px;
  height: 70px;
}

.uploadFile__title {
  font-size: 30px;
  color: #2c3e50;
  margin-top: 20px;
}

.uploadFile {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  background-color: #3874cb7d;
  padding: 8px;
  border-radius: 4px;
  box-sizing: border-box;
  width: 260px;
  height: 56px;
}

.uploadFile__title {
  margin: 0;
  padding-right: 10px;
  font-size: 30px;
  color: rgb(173, 173, 173);
  margin-bottom: 10px;
  margin-top: 7px;
}

#add-video__url {
  width: 284px;
}

.uploadFile {
  width: 310px;
}

.AddSource {
  margin-top: 25px;
  box-shadow: rgb(0 0 0 / 64%) 0px 1px 4px;
  padding: 40px 0px;
  max-width: 315px;
  align-content: center;
}

.DevicePage {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

[for="add-video__url"] {
  direction: rtl !important;
  width: 100%;
}

.AddSource {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  padding-top: 30px;
}

.DevicePage__button {
  height: 55px;
  width: 141px;
}

.url {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AddSource__subtitle {
  direction: rtl;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  font-size: 23px;
  font-weight: 500;
  width: 100%;
  text-align: center;
  color: #1876d2;
}

.AddSource__title {
}

.AddSource__title {
  margin: 0;
  padding: 0;
  align-self: flex-end;
  margin-bottom: 10px;
  font-size: 31px;
  margin-top: 6px;
  font-size: 39px;
  margin-bottom: 20px;
  letter-spacing: 2px;
  margin: 0;
  width: 100%;
  text-align: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.AddSource {
  padding-top: 2px;
  /* padding-right: 11px; */
  gap: 20px;
  padding: 0;
  width: 100%;
  max-width: 500px;
  padding-bottom: 40px;
}

.AddSource__subtitle {
  margin: 0;
  max-width: 300px;
  color: #adadad;
}

.uploadFile__title {
  margin: 0;
  padding-right: 10px;
  font-size: 60px;
  color: rgb(173, 173, 173);
  margin-bottom: 10px;
  margin-top: 0px;
  width: fit-content;
  padding: 0;
  margin: 0;
}

img.DevicePage__or-icon {
  width: 40px;
  height: 42px;
}

.DevicePage__or {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  gap: 10px;
}

hr.DevicePage__hr {
  width: calc(40% - 40px);
  border: 1px solid #1876d2;
  border-radius: 1px;
  margin: 0;
  padding: 0;
}

.DevicePage__or {
}

.buttons__containerx {
  margin-top: 0 !important;
}
.form__text_type_error{
  color: rgb(239 68 68 / var(--tw-text-opacity));
  display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    gap: 4px;
}
.recent-item-card__image {
  height: 200px;
  width: 250px;
  object-fit: fill;
}
.item-card {
  width: 200px;
  /* height: 200px; */
}

.reactItems {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}


.recent-item-card__image {

  margin-top: 10px;
}